<script setup>
  import { useVueFlow } from '@vue-flow/core'
  import { defineEmits } from 'vue'

  const emit = defineEmits(['nodeDoubleClicked']);

  const {
    onNodeDoubleClick
  } = useVueFlow()


  const openEditWindow = (id) => {
    emit('nodeDoubleClicked', id)
  }

  onNodeDoubleClick(e => openEditWindow(e.node.id))
</script>
