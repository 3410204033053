const API = {
  baseUrl: '' || window.location.origin,
  // bearerKey: 'YOUR_BEARER_KEY',

  async fetch(endpoint, method = 'GET', body = null) {
    const url = `${this.baseUrl}/api${endpoint}`;
    const token = document.querySelector("meta[name=csrf-token]")?.content;

    const headers = {
      'Content-Type': 'application/json',
      "X-CSRF-Token": token
    };

    const options = {
      method,
      headers,
      body: body ? JSON.stringify(body) : null,
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('API request failed:', error);
      throw error;
    }
  },
};

export default API;
