<script setup>
import { onMounted, onUnmounted } from 'vue'
import { Background } from '@vue-flow/background'
import { MiniMap } from '@vue-flow/minimap'
import { VueFlow, useVueFlow, ConnectionMode, Position } from '@vue-flow/core'
import { usePipelineStore } from '@/stores/pipeline'

import InteractionControls from '@/components/InteractionControls.vue'
import DefaultNode from '@/components/DefaultNode.vue'

const {
  addEdges,
  onConnect,
  onEdgesChange,
  onNodesChange,
  onPaneReady,
  toObject
} = useVueFlow()

const pipelineStore = usePipelineStore()

let flowInstance = undefined

const handleLoadPipelineEvent = async ({ detail }) => {
  console.log(`Loading pipeline ${detail.id}`)
  await pipelineStore.fetchPipeline(detail.id)
}

const props = defineProps({
  pipelineId: String,
  parentController: Object
})

const handleStateChange = () => {
  pipelineStore.putPipeline(props.pipelineId, toObject())
  pipelineStore.fetchPipeline(props.pipelineId)
}

const handleNodeDoubleClicked = (id) => {
  // Send message to Stimulus controller
  props.parentController.dispatch("nodeDoubleClicked", { detail: { id } })
}

const addNode = (event) => {
  props.parentController.dispatch("addNewClicked", { detail: {} })
}

onMounted(async () => {
  document.addEventListener("loadPipeline", handleLoadPipelineEvent)
  await pipelineStore.fetchPipeline(props.pipelineId)
})

onUnmounted(() => {
  document.removeEventListener("loadPipeline", handleLoadPipelineEvent)
})

onPaneReady((instance) => {
  flowInstance = instance
})

onEdgesChange((edges) => {
  const removedEdges = edges.filter(edge => edge.type === 'remove')
  if (removedEdges.length) { handleStateChange() }
})

onConnect((params) => {
  addEdges([{...params, ...{animated: true}}])

  handleStateChange()
})

onNodesChange((changes) => {
  let stateChanged = false
  changes.forEach((change) => {
    if (change.type == "remove") {
      stateChanged = true
    }
  })

  if (stateChanged) handleStateChange()
})

</script>

<template>
  <div style="height: 500px">
    <VueFlow
      :nodes="pipelineStore.nodes"
      :edges="pipelineStore.edges"
      :connection-mode="ConnectionMode.Strict"
      fit-view-on-init
    >
      <div class="absolute z-10 w-[200px] right-2 top-2">
        <button class="btn" @click="addNode">Add New</button>
      </div>
      <InteractionControls @node-double-clicked="handleNodeDoubleClicked" />
      <MiniMap />
      <Background />

      <template #node-default="nodeProps">
        <DefaultNode v-bind="nodeProps" />
      </template>

      <template #node-input="nodeProps">
        <DefaultNode v-bind="nodeProps" />
      </template>
    </VueFlow>
  </div>
</template>

<style>
/* import the necessary styles for Vue Flow to work */
@import '@vue-flow/core/dist/style.css';

/* import the default theme, this is optional but generally recommended */
@import '@vue-flow/core/dist/theme-default.css';
</style>
