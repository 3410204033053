import { defineStore } from 'pinia'
import api from '@/utils/api'

export const usePipelineStore = defineStore('pipeline', {
  state: () => ({
    pipelineData: null,
    loading: false,
    error: null
  }),
  actions: {
    async fetchPipeline(id) {
      this.loading = true
      try {
        this.pipelineData = await api.fetch(`/pipelines/${id}`)
      } catch (error) {
        console.error("Could not fetch pipeline", error)
        this.error = error
      } finally {
        this.loading = false
      }
    },
    async putPipeline(id, nodesAndEdges) {
      this.loading = true
      let edges = nodesAndEdges.edges.map((e) => {
        const param = {
          source_node: e.source,
          source_handle: e.sourceHandle,
          target_node: e.target,
          target_handle: e.targetHandle
        }
        if (!e.id.match(/^vueflow__edge-/)) {
          param.id = e.data.edge_id
        }

        return param
      })
      let nodes = nodesAndEdges.nodes

      try {
        await api.fetch(`/pipelines/${id}`, 'PUT', {
          pipeline: { graph: { nodes, edges } }
        })
      } catch (error) {
        console.error("Could not update pipeline", error)
        this.error = error
      } finally {
        this.loading = false
      }
    }
  },
  getters: {
    handlesForTask(state) {
      return (taskId) => {
        const handles = state.pipelineData?.handles.filter((handle) => handle.task_id === taskId) || []
        return handles.reduce((ios, handle) => {
          const [targets, sources] = ios
          handle.type == "target" ? targets.push(handle) : sources.push(handle)
          return [targets, sources]
        }, [[],[]])
      }
    },
    edges: (state) => state.pipelineData?.edges || [],
    nodes: (state) => state.pipelineData?.nodes || [],
    handles: (state) => state.pipelineData?.handles || [],
  }
})
