<script setup>

import { defineProps } from 'vue'
import { Handle, Position } from '@vue-flow/core'
import { usePipelineStore } from '@/stores/pipeline'

const props = defineProps(['id', 'label', 'data'])
const pipelineStore = usePipelineStore()
const [targetHandles, sourceHandles] = pipelineStore.handlesForTask(props.id)

</script>

<template>
  <Handle v-for="(item, index) in targetHandles"
    :key="index"
    :id="item.id"
    :position="Position.Top"
    :style="`margin-left: ${index * 10}px`"
    :type="item.type"
   />

  <div>
    {{ label }}
  </div>
  <div>{{ data.slug }}</div>

  <Handle v-for="(item, index) in sourceHandles"
    :key="index"
    :id="item.id"
    :position="Position.Bottom"
    :style="`margin-left: ${index * 10}px`"
    :type="item.type"
   />
</template>
